import extScriptLoaded from "./utils/script-loaded";

/**
 * A/B Test
 *
 * Implement Nativo in javascript
 */
function Sponsored() {
  let queue = [];
  this.init = function () {
    // For debugging --- Override window.useNativo variable by setting url param
    const urlParams = new URLSearchParams(window.location.search);
    const useNativo = urlParams.get("useNativo");
    const useNoSponsored = urlParams.get("useNoSponsored");

    if (useNoSponsored) {
      window.useNoSponsored = true;
    }
    if (window.useNoSponsored) {
      return;
    }

    console.log("sponsored ]: ", window.useNoSponsored);

    $(window).on(
      "injector:inject_widgets_injected",
      this,
      this.maybeRenderNativo
    );
    // $(window).on("sponsored:render", this, this.maybeRenderNativo);
    $(window).on("sponsored:load", this, this.loadScript);

    if (useNativo) {
      window.useNativo = true;
      $(window).trigger("sponsored:load");
    }

    // If Optimizely and testing query strings were not used wait and load after 1 second
    setTimeout(function () {
      if (
        typeof window.useNativo === "undefined" &&
        typeof window.useNoSponsored === "undefined"
      ) {
        $(window).trigger("sponsored:load");
      }
    }, 1000);
  };

  this.loadScript = (_) => {
    if (window.useNoSponsored) {
      return;
    }

    if (
      pubstack.all_content_types.includes(
        $(".pubstack-post-header").first().data("post_type")
      )
    ) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      if (window.useNativo) {
        script.setAttribute("data-ntv-set-no-auto-start", "");
        script.src = "//s.ntv.io/serve/load.js";
      }

      $("footer").append(script);

      // Render on the first article after the script is loaded
      extScriptLoaded(
        () => typeof OBR !== "undefined" || typeof PostRelease !== "undefined",
        () => {
          if (queue.length) {
            PostRelease.Start({ ptd: queue });
          }
        }
      );
    }
  };

  const viewPortHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  const viewPortMultiplier = 1;
  const rootMargin = Math.floor(viewPortMultiplier * viewPortHeight);

  // Observer to detect Nativo
  const nativo_observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          const el = $(entry.target);
          renderNativo(el);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      rootMargin: `0px 0px ${rootMargin}px 0px`,
      threshold: 0,
    }
  );

  // Observer to detect Nativo is rendered. If not, show pubstack articles.
  const nativo_render_observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        const el = $(entry.target);
        const nativo_rendered = el.find(".ntv-card").not(":empty");
        if (nativo_rendered.length) {
          el.find(
            `.card-blog:not(.ntv-card):gt(${4 - nativo_rendered.length})`
          ).remove();
          if (nativo_rendered.length === el.find(".ntv-card").length) {
            observer.unobserve(entry.target);
          }
        }

        // add class to not empty last card blog
        el.find(".card-blog").not(":empty").last().addClass("last-card-blog");
      });
    },
    {
      threshold: 0,
    }
  );

  const renderNativo = (appendTo) => {
    // Live Nativo PTD IDs
    let IDs = ["1120057", "1120058"];
    let ID3 = 1120059;

    // Check environment
    let first_article = $(".pubstack-post-header:first");

    if (first_article.length) {
      let targeting = first_article.data("page_targeting");

      if (
        typeof targeting === "object" &&
        typeof targeting.environment === "string"
      ) {
        if (targeting.environment === "test") {
          // QA Nativo PTD IDs
          IDs = ["1117850", "1117859"];
          ID3 = 1120038;
        }
      }
    }

    const header = appendTo.closest("article").prev();
    // Added to clean up any unplaced units from prior iscroll articles
    $(".ntv-div.n1").each(function (index) {
      $(this).removeClass("n1");
    });
    $(".ntv-div.n2").each(function (index) {
      $(this).removeClass("n2");
    });

    appendTo
      .append('<div class="card-blog ntv-div ntv-card n1"></div>')
      .append('<div class="card-blog ntv-div ntv-card n2"></div>');

    // Rules require 2 Nativo slots for first article in scroll, 3 for subsequent on
    if ($(".pubstack-post-header").index(header) >= 1) {
      appendTo.append('<div class="card-blog ntv-div ntv-card n3"></div>');
      IDs.push(ID3);
    }
    nativo_render_observer.observe(appendTo[0]);

    // Load Nativo Read More articles.
    if (typeof PostRelease !== "undefined") {
      PostRelease.Start({ ptd: IDs });
    } else {
      queue = [...queue, ...IDs];
    }
  };

  this.maybeRenderNativo = (_, $article) => {
    if (window.useNoSponsored) {
      return;
    } else if (window.useNativo) {
      const $el = $article.find(".read-more-blog");
      if ($el.length) {
        nativo_observer.observe($el[0]);
      }
    }
  };
}
export default new Sponsored();
